import React from 'react'

import './CherryCast.css'
import VideoPlayer from '../components/video/VideoPlayer'

const Ruth = require('../images/Ruth.mp4')
const Viv = require('../images/Viv.mp4')

const CastPage = () => (
  <div className="cast">
    <h2 className="header-cast">Cherry cast...</h2>
    <div className="cast-content">
      <p className="body-cast">
        Yep, back in the day when to work required a terminal we were an
        employer. Now we bring in the right peeps as and when a project
        requires. Nimble, like.
      </p>

      <div className="cast-member">
        <VideoPlayer
          url={Ruth}
          width={350}
          height={350}
          autoplay
          playing
          volume={0}
          muted
          playsinline
        />
        <h3 className="cast-member-h3">#ladyboss*1</h3>
        <h4 className="cast-member-h4">Ruth Gavin</h4>
        <p className="cast-member-p">
          First. But only alphabetically speaking.
        </p>
      </div>

      <div className="cast-member">
        <VideoPlayer
          url={Viv}
          width={350}
          height={350}
          autoplay
          playing
          volume={0}
          muted
          playsinline
        />
        <h3 className="cast-member-h3">#ladyboss*2</h3>
        <h4 className="cast-member-h4">Vivienne Hamilton</h4>
        <p className="cast-member-p">
          Yarn spinner. Irish publican’s daughter.
        </p>
      </div>
    </div>

    <div className="cast-detail">
      <div className="cast-detail-filler" />
      <div className="cast-detail-content">
        <p className="cast-detail-p">
          *We know, we know. No-one should be saying ladyboss, girlboss – or
          even the hilarious #EntreprenHER. Yeurch! But while women on FTSE 100
          boards still number 28%, and an almost unbelievable 89% of creatives
          in advertising are men - well, we’re pushing the gender agenda.
        </p>
      </div>
      <div className="cast-detail-right" />
    </div>
  </div>
)

export default CastPage
